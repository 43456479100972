/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { animated } from "react-spring"
import { ChildImageSharp } from "../types"

type ProjectItemProps = {
  node: {
    color: string
    title: string
    slug: string
    service: string
    client: string
    cover: ChildImageSharp
  }
  style: any
}

const ProjectItem = ({ node, style }: ProjectItemProps) => (
  <animated.div
    sx={{
      position: `relative`,
      "&:before": {
        content: `""`,
        display: `block`,
        paddingTop: `100%`,
      },
    }}
    style={style}
  >
    <div
      sx={{
        left: 0,
        height: `100%`,
        position: `absolute`,
        top: 0,
        width: `100%`,
        a: {
          color: `white`,
          // opacity: 0,
          position: `absolute`,
          top: 0,
          left: 0,
          width: `100%`,
          height: `100%`,
          padding: 4,
          textDecoration: `none`,
          transition: `all 0.3s ease-in-out`,
          zIndex: 10,
          // "&:hover": {
          //   color: `white`,
          //   opacity: 1,
          //   textDecoration: `none`,
          // },
        },
      }}
    >
      <img
          alt=""
          src={node.cover.childImageSharp.fluid.tracedSVG}
          sx={{
            position: `absolute`,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: `100%`,
            height: `100%`,
            filter: `invert(100%)`,
            zIndex: -1,
            opacity: 0.08,
            objectFit: `cover`,
          }}
        />
        <div
          sx={{
            filter: `opacity(35%)`,
            // backgroundColor: `#000`,
            height: `100%`,
            left: 0,
            position: `absolute`,
            top: 0,
            width: `100%`,
            zIndex: -2,
          }}
        />
        <Link to={node.slug} aria-label={`View detail page of ${node.title}`}>
          <div
            sx={{
              "> div": {
                height: `100%`,
                left: 0,
                position: `absolute !important`,
                top: 0,
                width: `100%`,
                overflow: `hidden`,
                userSelect: `none`,
                "> div": {
                  position: `static !important`,
                },
                "img": {
                  transition: `transform 1s ease !important`,
                  "&:hover": {
                    transform: `scale(1.1)`,
                  },
                },
              },
            }}
          >
            <Img fluid={node.cover.childImageSharp.fluid} />
          </div>
          <div sx={{
            position: `absolute`,
            zIndex: 11,
            fontSize: 5,
            fontWeight: `bold`,
            textShadow: `-1px 1px 1px #000, 1px 1px 0 #000, 1px -1px 1px #000`,
          }}>
            {node.title}
          </div>
          {/* <div sx={{ fontSize: 2 }}>{node.client}</div> */}
      </Link>
    </div>
  </animated.div>
)

export default ProjectItem
